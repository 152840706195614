import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
} from "react";
import axios from "axios";
import { MAIN_URL } from "../config";
import { GetJWT, CreateJWT } from "./useJWT";
import { setUserId } from "../amplitude";
interface AuthContextProps {
  register: (userName: string, email: string, password: string) => void;
  signin: (email: string, password: string) => Promise<boolean>;
  signout: () => void;
  createProfile?: (user_id: number, url: string) => Promise<boolean>;
  tokenUser: string | null;
  userInfo: Record<string, any>;
  isLoading: boolean;
  user: any;
  user_id: number;
  forgotPassword: (email: string) => Promise<boolean>;
  resetPassword: (token: string, password: string) => Promise<boolean>;
  isVisitor: boolean;
}

const AuthContext = createContext<AuthContextProps | undefined>(undefined);

interface AuthProviderProps {
  children: ReactNode;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [user, setUser] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true); // Start with loading as true
  const [tokenUser, setTokenUser] = useState<string | null>(null);
  const [isVisitor, setIsVisitor] = useState<boolean>(true);

  useEffect(() => {
    const initializeAuth = async () => {
      setIsLoading(true);
      try {
        const storedUser = localStorage.getItem("user");
        const storedToken = localStorage.getItem("token");

        if (storedUser && storedToken) {
          const userData = JSON.parse(storedUser);
          setUser(userData);
          setIsVisitor(true);
          setTokenUser(storedToken);
        }
      } catch (error) {
        console.error("Failed to initialize authentication", error);
      } finally {
        setIsLoading(false);
      }
    };

    initializeAuth();
  }, []);

  useEffect(() => {
    if (user) {
      localStorage.setItem("user", JSON.stringify(user));
    } else {
      localStorage.removeItem("user");
    }
  }, [user]);

  useEffect(() => {
    if (tokenUser) {
      localStorage.setItem("token", tokenUser);
    } else {
      localStorage.removeItem("token");
    }
  }, [tokenUser]);

  const handleAuthentication = async (
    endpoint: string,
    data: Record<string, any>
  ) => {
    setIsLoading(true);

    try {
      const response = await axios.post(
        `${MAIN_URL}clientsystem/${endpoint}`,
        //`http://localhost:1020/${endpoint}`,

        data,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const userInfo = response.data;
      setUser(userInfo);
      //setUserId(userInfo.user_id);
      await CreateJWT(userInfo.user_id, setTokenUser);
      return true;
    } catch (error) {
      console.error(`${endpoint} error`, error);
      return false;
    } finally {
      //setIsLoading(false);
      return true;
    }
  };

  const register = (
    userName: string,
    email: string,
    password: string
  ): Promise<boolean> => {
    return handleAuthentication("register", {
      username: userName,
      email,
      password,
    });
  };

  const signin = async (email: string, password: string): Promise<boolean> => {
    try {
      const response = await axios.post(
        `${MAIN_URL}clientsystem/signin`,
        //`http://localhost:1020/signin`,
        { email, password },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const userInfo = response.data;
      setUser(userInfo);
      setIsVisitor(true);
      //setUserId(userInfo.user_id);
      await CreateJWT(userInfo.user_id, setTokenUser);
      return true;
    } catch (error) {
      console.error("Signin error", error);
      return false;
    } finally {
      setIsLoading(false);
    }
  };

  const signout = () => {
    setUser(null);
    setTokenUser(null);
    setUserId("");
    localStorage.removeItem("user");
    localStorage.removeItem("token");
  };

  const forgotPassword = async (email: string): Promise<boolean> => {
    try {
      await axios.post(
        `${MAIN_URL}clientsystem/forgot-password`,
        { email },
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      return true;
    } catch (error) {
      console.error("Forgot password error", error);
      return false;
    } finally {
      //setUserId("");
    }
  };

  const resetPassword = async (
    token: string,
    password: string
  ): Promise<boolean> => {
    setIsLoading(true);
    try {
      await axios.post(
        `${MAIN_URL}clientsystem/reset-password`,
        { token, password },
        { headers: { "Content-Type": "application/json" } }
      );
      return true;
    } catch (error) {
      console.error("Reset password error", error);
      return false;
    } finally {
      setIsLoading(false);
    }
  };
  /*
  const createProfile = async (
    user_id: number,
    website: string
  ): Promise<boolean> => {
    setIsLoading(true);
    try {
      const response = await axios.post(
        `${MAIN_URL}clientsystem/create-profil`,
        //`http://localhost:1020/create-profile`,
        { user_id: user_id.toString(), website },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.status === 200) {
        setIsVisitor(true);
        setUser((prevUser: any) => ({
          ...prevUser,
          Is_visitor: true,
        }));
        return true;
      }
      return false;
    } catch (error) {
      console.error("Create profil error", error);
      return false;
    } finally {
      setIsLoading(false);
    }
  };*/

  return (
    <AuthContext.Provider
      value={{
        register,
        signin,
        signout,
        tokenUser,
        userInfo: user || {},
        isLoading,
        user,
        user_id: user?.user_id,
        forgotPassword,
        resetPassword,
        //createProfile,
        isVisitor,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default function useAuth() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
}
