import { useState, useEffect } from "react";
import { MAIN_URL } from "../config";
import axios from "axios";

interface People {
  deputyId: string;
  fullName: string;
  groupNameShort: string;
  firstName: string;
  lastName: string;
  amendmentsCount: number;
  amendmentsAccepted: number;
}

interface PeopleData {
  People: People[];
  total_pages: number;
}

interface UsePeopleDataResponse {
  data: PeopleData | null;
  loading: boolean;
  error: string | null;
}

const usePeople = (selection?: string): UsePeopleDataResponse => {
  const [data, setData] = useState<PeopleData | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);

      try {
        const response = await axios.get(`${MAIN_URL}people/list`, {
          //const response = await axios.get(`http://localhost:1330/list`, {
          params: {
            selection: selection,
          },
        });
        /*const response = await axios.get(`${MAIN_URL}textprovider/files`);*/
        setData(response.data);
      } catch (err) {
        if (axios.isAxiosError(err) && err.response) {
          setError(`Error: ${err.response.statusText}`);
        } else if (err instanceof Error) {
          setError(err.message);
        } else {
          setError("An unknown error occurred");
        }
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [selection]);

  return { data, loading, error };
};

export default usePeople;
