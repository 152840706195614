import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import ProfilPic from "../img/profilPic";
import Category from "../text/Category";
import { useNavigate } from "react-router-dom";
import Tooltip from "../cta/Tooltip";
import Score from "../alerts/Score";

interface SpeechUnitProps {
  deputyId: string;
  fullName: string;
  typeMeeting?: string;
  text: string;
  score?: string;
  url?: string;
  signalName?: string;
  type?: string;
  activeAction?: boolean;
}

const SpeechContainer = styled.div<{ activeAction?: boolean }>`
  display: flex;
  padding: 20px;
  flex-direction: row;
  align-items: flex-start;
  gap: 10px;
  border-radius: 18px;
  max-width: 100%;
  background-color: ${({ activeAction }) =>
    activeAction ? "#FFF7F2" : "#fcfcfc"};
  box-sizing: border-box;
  border: ${({ activeAction }) =>
    activeAction ? "1px solid #ff734f" : "1px solid #ebebeb"};
  &:hover {
    background-color: ${({ activeAction }) =>
      activeAction ? "#FFF7F2" : "#f9f9f9"};
  }
  cursor: pointer;
  width: ${({ activeAction }) => (activeAction ? "95%" : "100%")};
`;

const ProfilPicContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SpeechContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  padding: 0px 5px;
`;

const Title = styled.span`
  color: #464646;
  font-family: "Sharp Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 14px;
  text-align: left;
  padding: 0;
  margin: 0;
  cursor: pointer;
`;

const Text = styled.span`
  color: #6e6e6e;
  font-family: "Sharp Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  text-align: left;
  padding: 0;
  margin: 0;
  display: -webkit-box;
  overflow: hidden;
  transition: all 0.3s ease;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
`;

const SeeMoreButton = styled.button`
  background: none;
  border: none;
  color: #ff734f;
  font-family: "Sharp Sans";
  font-size: 12px;
  font-weight: 600;
  cursor: pointer;
  padding: 0px 0;
  margin-top: 4px;

  &:hover {
    text-decoration: underline;
  }
`;

const Action: React.FC<SpeechUnitProps> = ({
  deputyId,
  fullName,
  typeMeeting,
  text,
  score,
  url,
  signalName,
  type,
  activeAction,
}) => {
  const navigate = useNavigate();
  const [isExpanded, setIsExpanded] = useState(false);
  const [isTruncated, setIsTruncated] = useState(false);
  const textRef = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    if (textRef.current && text) {
      setIsTruncated(text.length > 430);
    }
  }, [text]);

  let titleText = "";
  if (type === "meeting") {
    titleText = `${fullName} est intervenu(e) en ${typeMeeting}`;
  } else if (type === "amendment") {
    titleText = `${fullName} a déposé un amendement`;
  } else if (type === "speech") {
    titleText = `${fullName} a pris la parole`;
  } else if (type === "article") {
    titleText = `${fullName}`;
  }

  const displayText = isExpanded
    ? text || ""
    : (text || "").slice(0, 450) + (isTruncated ? "..." : "");

  const handleClick = () => {
    if (url) {
      navigate(`/${url}`);
    }
  };

  const handleSeeMoreClick = (e: React.MouseEvent) => {
    e.stopPropagation(); // Prevent the parent click event from firing
    setIsExpanded(!isExpanded);
  };

  if (!score) {
    score = "low";
  }

  return (
    <SpeechContainer onClick={handleClick} activeAction={activeAction}>
      <ProfilPicContainer>
        <ProfilPic deputyId={deputyId} size={37} />
      </ProfilPicContainer>
      <SpeechContent>
        <Header>
          <Title>{titleText}</Title>
          <Tooltip
            text={` ${
              signalName
                ? `Cet élément a été capté par le signal : ${signalName}`
                : "Cet élément n'est pas lié à un signal"
            }`}
          >
            <Score>{score}</Score>
          </Tooltip>
        </Header>
        <Content>
          <Text ref={textRef}>{displayText}</Text>
          {isTruncated && (
            <SeeMoreButton onClick={handleSeeMoreClick}>
              {isExpanded ? "Voir moins" : "Voir plus"}
            </SeeMoreButton>
          )}
        </Content>
      </SpeechContent>
    </SpeechContainer>
  );
};

export default Action;
