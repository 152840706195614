import React from "react";
import useSpeech from "../../../hooks/useSpeech";
import { useParams } from "react-router-dom";
import useInteraction from "../../../hooks/useInteraction";
import Action from "../../../components/speech/Action";
import styled from "styled-components";

const FeedList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 15px;
  align-self: stretch;
`;

const Discusions = () => {
  const { interactionId, deputyId } = useParams();
  const { data, loading, error } = useInteraction(interactionId);
  console.log("data", data);
  return (
    <FeedList>
      {data?.interactions.map(
        (item: any, index: any) =>
          item && (
            <Action
              key={index}
              fullName={item.fullName || ""}
              deputyId={item.deputyId || ""}
              text={item.content || ""}
              score={item.score || "low"}
              url={`people/${item.deputyId}/discusions/${item.interactionId}`}
              signalName={item.signalName || ""}
              type={item.type || ""}
              activeAction={deputyId === item.deputyId}
            />
          )
      )}
    </FeedList>
  );
};

export default Discusions;
