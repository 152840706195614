import React, { useState } from "react";
import styled from "styled-components";
import { ChevronLeft, ChevronRight } from "lucide-react";

interface PageNavigatorProps {
  pageSelected: number;
  pageList: number[];
  setPageSelected: (page: number) => void;
}

const PageNavigatorContainer = styled.div`
  display: flex;
  height: 36px;
  align-items: center;
  border-radius: 6px;
  border: 1px solid var(--Main-stroke-1, #ebebeb);
  width: fit-content;
`;

const PageNavigatorItem = styled.button<{ selectedItem: boolean }>`
  display: flex;
  padding: 0px 7px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  background-color: ${({ selectedItem }) =>
    selectedItem ? "var(--Main-color, #ff734f)" : "white"};
  color: ${({ selectedItem }) => (selectedItem ? "white" : "black")};
  border: none;
  cursor: pointer;
  &:hover {
    //background-color: #f0f0f0;
    color: black;
  }
  font-family: "Sharp Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

const NavButton = styled.button`
  display: flex;
  padding: 0px 8px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  border: none;
  cursor: pointer;
  background-color: #ffffff;
  &:hover {
    background-color: #f0f0f0;
  }
`;

const Ellipsis = styled.span`
  display: flex;
  padding: 0px 7px;
  align-items: center;
  color: black;
  font-family: "Sharp Sans";
  font-size: 12px;
`;

const PageNavigator: React.FC<PageNavigatorProps> = ({
  pageSelected,
  pageList,
  setPageSelected,
}) => {
  const getDisplayedPages = () => {
    if (pageList.length <= 7) return pageList;

    const lastPage = pageList[pageList.length - 1];

    // If selected page is in first 4 pages, show first 6 pages + last page
    if (pageSelected <= 4) {
      const firstPages = pageList.slice(0, 6);
      return [...firstPages, -1, lastPage];
    }

    // If selected page is near the end of the list
    if (pageSelected > pageList.length - 4) {
      const lastPages = pageList.slice(-6);
      return [pageList[0], -1, ...lastPages];
    }

    // If selected page is in the middle (show selected page and surrounding pages)
    return [
      pageList[0],
      -1,
      pageSelected - 1,
      pageSelected,
      pageSelected + 1,
      -1,
      lastPage,
    ];
  };

  return (
    <PageNavigatorContainer>
      <NavButton
        onClick={() => pageSelected > 1 && setPageSelected(pageSelected - 1)}
        disabled={pageSelected <= 1}
      >
        <ChevronLeft size={16} />
      </NavButton>
      {getDisplayedPages().map((page, index) =>
        page === -1 ? (
          <Ellipsis key={`ellipsis-${index}`}>...</Ellipsis>
        ) : (
          <PageNavigatorItem
            key={page}
            selectedItem={page === pageSelected}
            onClick={() => setPageSelected(page)}
          >
            {page}
          </PageNavigatorItem>
        )
      )}
      <NavButton
        onClick={() =>
          pageSelected < pageList.length && setPageSelected(pageSelected + 1)
        }
        disabled={pageSelected >= pageList.length}
      >
        <ChevronRight size={16} />
      </NavButton>
    </PageNavigatorContainer>
  );
};

export default PageNavigator;
