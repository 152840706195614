import { useState, useEffect, useCallback } from "react";
import axios from "axios";

export enum AnalysisStatus {
  NO_ANALYSIS = "noAnalysis",
  STARTED = "started",
  ERROR = "error",
  ENDED = "ended",
}

interface UseAnalysisResponse {
  startAnalysis: (textId: string, userId: number) => Promise<void>;
  analysisStatus: AnalysisStatus;
  error: string | null;
}

const useAnalysis = (textId?: string, userId?: number): UseAnalysisResponse => {
  const [analysisStatus, setAnalysisStatus] = useState<AnalysisStatus>(
    AnalysisStatus.NO_ANALYSIS
  );
  const [error, setError] = useState<string | null>(null);
  const [pollingInterval, setPollingInterval] = useState<NodeJS.Timeout | null>(
    null
  );

  const checkStatus = useCallback(async (textId: string, userId: number) => {
    try {
      const response = await axios.get(
        "http://localhost:1040/analysis/status",
        {
          params: {
            text_id: textId,
            user_id: userId,
          },
        }
      );

      setAnalysisStatus(response.data.analysisStatus);
      return response.data.analysisStatus;
    } catch (err) {
      setAnalysisStatus(AnalysisStatus.ERROR);
      setError("Error checking analysis status");
      return AnalysisStatus.ERROR;
    }
  }, []);

  // Extract polling logic to reusable function
  const startPolling = useCallback(
    (textId: string, userId: number) => {
      const interval = setInterval(async () => {
        const status = await checkStatus(textId, userId);
        if (
          status === AnalysisStatus.ENDED ||
          status === AnalysisStatus.ERROR
        ) {
          if (pollingInterval) {
            clearInterval(pollingInterval);
            setPollingInterval(null);
          }
        }
      }, 5000);

      setPollingInterval(interval);
    },
    [checkStatus, pollingInterval]
  );

  // Add initial status check
  useEffect(() => {
    const checkInitialStatus = async (textId: string, userId: number) => {
      const status = await checkStatus(textId, userId);
      if (status === AnalysisStatus.STARTED) {
        startPolling(textId, userId);
      }
    };

    if (textId && userId) {
      checkInitialStatus(textId, userId);
    }
  }, [textId, userId, checkStatus, startPolling]);

  // Update startAnalysis to use the new startPolling function
  const startAnalysis = useCallback(
    async (textId: string, userId: number) => {
      setError(null);
      setAnalysisStatus(AnalysisStatus.STARTED);

      try {
        await axios.get("http://localhost:1040/analysis", {
          params: {
            text_id: textId,
            user_id: userId,
          },
        });

        startPolling(textId, userId);
      } catch (err) {
        setError("Error starting analysis");
        setAnalysisStatus(AnalysisStatus.ERROR);
      }
    },
    [startPolling]
  );

  // Cleanup interval on unmount
  useEffect(() => {
    return () => {
      if (pollingInterval) {
        clearInterval(pollingInterval);
      }
    };
  }, [pollingInterval]);

  return { startAnalysis, analysisStatus, error };
};

export default useAnalysis;
