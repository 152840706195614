import { useEffect, useState } from "react";
import { Outlet, useParams, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import Button from "../../../components/cta/Button";
import FileProgressBar from "../../../components/cta/FileProgressBar";
import FileMenu from "../../../components/nav/FileMenu";
import useStateInfo from "../../../hooks/useState";
import useFollow from "../../../hooks/useFollow";
import useAuth from "../../../hooks/useAuth";
import PageContainer from "../../../components/containers/PageContainer";

const Content = styled.div`
  display: flex;
  padding: 24px 48px;
  flex-direction: column;
  align-items: flex-end;
  gap: 17px;
  align-self: stretch;
  border-radius: 20px;
  background: #232129;
  padding-bottom: 50px;
`;

const Description = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 28px;
  align-self: stretch;
`;

const TitleOverview = styled.div`
  width: 720.106px;
  height: auto;
  color: #fff;
  text-align: center;
  font-family: "Sharp Sans";
  font-size: 28px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
`;

const File = () => {
  const { fileId, amendtsId } = useParams<{
    fileId: string;
    amendtsId: string;
  }>();
  const {
    data: stateInfo,
    loading,
    error,
  } = useStateInfo(fileId, amendtsId || undefined);
  const [rank, setRank] = useState<number | null>(null);
  const [title, setTitle] = useState<string>("");
  const [subtitle, setSubtitle] = useState<string>("");
  const [textId, setTextId] = useState<string>("");
  const [stageFileId, setStageFileId] = useState<string>("");
  const [hasAmendments, setHasAmendments] = useState<boolean>(false);
  const { user } = useAuth();
  const {
    isFollowing,
    toggleFollow,
    error: followError,
  } = useFollow(fileId || "", user?.user_id, "File");

  const handleStepClick = (
    rank: number,
    title: string,
    subtitle: string,
    textId: string,
    stageFileId: string,
    hasAmendments: boolean
  ) => {
    setRank(rank);
    setTitle(title);
    setSubtitle(subtitle);
    setTextId(textId);
    setStageFileId(stageFileId);
    setHasAmendments(hasAmendments);
    console.log("rank", rank);
    console.log("title", title);
    console.log("subtitle", subtitle);
    console.log("textId", textId);
    console.log("stageFileId", stageFileId);
  };
  // Move the state updates into a useEffect
  useEffect(() => {
    // find state with currentStep = True
    if (stateInfo && stateInfo.states.length > 0) {
      const lastState = stateInfo.states.find(
        (state) => state.currentStep === true
      );
      setSubtitle(lastState?.subtitle || "");
      setTextId(lastState?.textId || "");
      setStageFileId(lastState?.stageFileId || "");
      setTitle(lastState?.title || "");
      setHasAmendments(lastState?.hasAmendments || false);
      console.log("subtitle", lastState?.subtitle);
    }
  }, [stateInfo]);

  const fields = [
    { name: "Overview", link: "overview" },
    { name: "Texte", link: "text", disabled: !textId },
    { name: "Amendements", link: "amendts", disabled: !hasAmendments },
    { name: "Discussions", link: "speeches", disabled: true },
  ];

  console.log(
    "stateInfo",
    stateInfo?.states.find((state) => state.currentStep === true)
  );

  console.log("textId", textId);
  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <PageContainer>
      <Content>
        <Button
          typeButton="secondary"
          typee={"test"}
          stretch={false}
          onClick={toggleFollow}
        >
          {isFollowing ? "Ne plus suivre" : "Suivre"}
        </Button>
        {stateInfo && (
          <Description>
            <TitleOverview>{stateInfo.title}</TitleOverview>
            <FileProgressBar
              steps={stateInfo.states}
              onStepClick={handleStepClick}
            />
          </Description>
        )}
      </Content>
      <FileMenu
        text={"Overview"}
        iconSrc={"https://via.placeholder.com/18"}
        link={"/overview"}
        hasText={!!textId}
        hasAmendments={hasAmendments}
        hasSpeeches={false}
        fields={fields}
        hidden={amendtsId ? true : false}
      />
      <Outlet
        context={{
          rank: rank,
          title: title,
          fileTitle: stateInfo?.title,
          subtitle: subtitle,
          textId: textId,
          fileId: fileId,
          stageFileId: stageFileId,
          hasAmendments: !!stageFileId,
          userId: user?.user_id,
        }}
      />
    </PageContainer>
  );
};

export default File;
