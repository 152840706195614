import { useOutletContext } from "react-router-dom";
import styled from "styled-components";

import Title from "../../../components/text/Title";
import Text from "../../../components/text/Text";
import ProgressBar from "../../../components/cta/ProgressBar";
import LineChartTest from "../../../components/charts/LineChartTest";
//import data from "../../data.json";
import Metric from "../../../components/text/Metric";
import useChart from "../../../hooks/useChart";
import LineChart from "../../../components/charts/LineChart";
import Loader from "../../../components/interaction/Loader";
import useCount from "../../../hooks/useCount";
import Tooltip from "../../../components/cta/Tooltip";
import useProfil from "../../../hooks/useProfil";
import Module from "../../../components/containers/Module";
import useSpeech from "../../../hooks/useSpeech";
import SpeechUnit from "../../../components/speech/Action";
import Modules from "../../../components/containers/Modules";

interface OutletContext {
  deputyId: string;
  data: any;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  gap: 20px;
`;

const SpeechesProfil = () => {
  const { deputyId, data } = useOutletContext<OutletContext>();

  const {
    data: datacspeech,
    loading: loadingspeech,
    error: errorspeech,
  } = useSpeech(undefined, deputyId);

  if (loadingspeech) {
    return <Loader />;
  }

  return (
    <Modules>
      <Container>
        {datacspeech?.Speeches.map((speech: any) => (
          <SpeechUnit
            key={speech.speechId}
            deputyId={deputyId}
            fullName={speech.fullName}
            typeMeeting={speech.typeMeeting}
            text={speech.text}
          />
        ))}
      </Container>
    </Modules>
  );
};

export default SpeechesProfil;
