import React, { useState, useEffect, useCallback } from "react";
import { useOutletContext } from "react-router-dom";
import styled from "styled-components";
import SpeechesExplorer from "../../../components/pageSection/SpeechesExplorer";

const SubText = styled.div`
  color: #464646;
  font-family: "Sharp Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100px;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
`;

const SubSummaryContainer = styled.div`
  width: 100%;
`;
const SignalsInterventions: React.FC = () => {
  const { signalId, userId } = useOutletContext<{
    signalId: string;
    userId: string;
  }>();
  console.log("Signal:", signalId);
  console.log("User:", userId);
  return (
    <SubSummaryContainer>
      <SpeechesExplorer userId={userId} signalId={signalId} />
    </SubSummaryContainer>
  );
};

export default SignalsInterventions;
