import * as amplitude from "@amplitude/analytics-browser";

const AMPLITUDE_API_KEY = "a2601f2d53771f512b80923c595e13f7";

export const initAmplitude = () => {
  amplitude.init(AMPLITUDE_API_KEY, {
    defaultTracking: true, // Automatically tracks page views, clicks, etc.
    serverZone: "EU",
  });
};

export const logEvent = (
  eventName: string,
  eventProperties?: Record<string, any>
) => {
  amplitude.track(eventName, eventProperties);
};

export const setUserId = (userId: string) => {
  amplitude.setUserId(userId);
};

export const setUserProperties = (properties: amplitude.Identify) => {
  amplitude.identify(properties);
};
