import React, { useState, useEffect, useCallback } from "react";
import AmdtsExplorer from "../../../components/pageSection/AmdtsExplorer";
import { useOutletContext, useParams } from "react-router-dom";
const SignalsAmendments: React.FC = () => {
  const { signalId, userId } = useOutletContext<{
    signalId: string;
    userId: string;
  }>();
  console.log("Signal:", signalId);
  console.log("User:", userId);
  if (!userId || !signalId) {
    return <div>Error: Missing userId or signalId</div>;
  }

  return <AmdtsExplorer userId={userId} signalId={signalId} />;
};

export default SignalsAmendments;
