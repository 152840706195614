import { useState, useEffect } from "react";
import axios from "axios";
import { MAIN_URL } from "../config";

interface CountSignals {
  count: number;
}

interface UseCountSignalsResponse {
  count: CountSignals | null;
  loading: boolean;
  error: string | null;
}

const useCountSignals = (userId?: string): UseCountSignalsResponse => {
  const [count, setCount] = useState<CountSignals | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);

      try {
        const response = await axios.get(
          `${MAIN_URL}/textprovider/countSignals`,
          {
            params: {
              userId: userId,
            },
          }
        );
        setCount(response.data);
      } catch (err) {
        if (axios.isAxiosError(err) && err.response) {
          setError(`Error: ${err.response.statusText}`);
        } else if (err instanceof Error) {
          setError(err.message);
        } else {
          setError("An unknown error occurred");
        }
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [userId]);

  return { count, loading, error };
};

export default useCountSignals;
