import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import styled from "styled-components";
import useAlert from "../../hooks/useAlert";
import { Signal } from "../../types/Signal";
import Button from "../../components/cta/Button";
import BackButton from "../../components/nav/BackButton";
import PageContainer from "../../components/containers/PageContainer";
import SearchBar from "../../components/search/searchBar";
import Loader from "../../components/interaction/Loader";
import FileMenu from "../../components/nav/FileMenu";
import { Outlet } from "react-router-dom";
import useAuth from "../../hooks/useAuth";

const SearchContainer = styled.div`
  width: 600px;
`;

const DescriptionHeader = styled.p`
  color: #fff;
  font-family: "Sharp Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
  padding: 0;
  span {
    color: #ff734f;
    font-weight: 700;
  }
`;

const Header = styled.div`
  display: flex;
  height: 135px;
  padding: 0px 40px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 5px;
  align-self: stretch;
  border-radius: 19px;
  background: #232129;
  width: calc(100% - 80px);
  box-sizing: border-box;
  width: 100%;
  position: relative;
`;

const TitleHeader = styled.h1`
  color: #fff;
  font-family: "Sharp Sans";
  font-size: 28px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  margin: 0;
  padding: 0;
`;

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  width: 100%;
`;

const fields = [
  { name: "Amendements", link: "amendments" },
  { name: "Textes", link: "articles" },
  { name: "Interventions", link: "interventions" },
];

const Summary = () => {
  const { signal_id } = useParams<{ signal_id: string }>();
  const { getAlert, deleteAlert, loading } = useAlert();
  const [signal, setSignal] = useState<Signal | undefined>(undefined);
  const navigate = useNavigate();
  const { user } = useAuth();

  useEffect(() => {
    if (signal_id) {
      console.log("Signal ID:", signal_id);
      getAlert(signal_id).then((data) => {
        if (data) {
          setSignal(data);
          console.log("Alert:", data);
        }
      });
    }
  }, [signal_id, getAlert]);

  const handleModify = () => {
    if (signal) {
      navigate(`/alerts/config/${signal.signal_id}`, { state: { signal } });
    }
  };

  const handleDelete = async () => {
    if (signal_id) {
      try {
        await deleteAlert(signal_id);
        navigate("/alerts"); // Navigate back to the alerts list after deletion
      } catch (error) {
        console.error("Error deleting alert:", error);
        // You might want to show an error message to the user here
      }
    }
  };

  if (loading) {
    return (
      <PageContainer>
        <Loader page={true} />
      </PageContainer>
    );
  }

  return (
    <PageContainer>
      <SearchContainer>
        <SearchBar />
      </SearchContainer>
      <Header>
        <TitleHeader>{signal?.name}</TitleHeader>
        <DescriptionHeader>
          Retrouvez ici votre tous les éléments captés.
        </DescriptionHeader>
      </Header>
      <FileMenu
        text={"Overview"}
        iconSrc={"https://via.placeholder.com/18"}
        link={"/alerts"}
        hasText={true}
        //hasAmendments={hasAmendments}
        //hasSpeeches={false}
        fields={fields}
      />

      <Outlet context={{ signalId: signal_id, userId: user?.user_id }} />
      <Footer>
        {/*
        <Button typeButton="secondary" onClick={handleModify}>
          Modifier
        </Button>*/}
        <Button typeButton="secondary" onClick={handleDelete}>
          Supprimer
        </Button>
      </Footer>
    </PageContainer>
  );
};

export default Summary;
