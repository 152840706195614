import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import useAuth from "../../hooks/useAuth"; // Adjust the import path as necessary

const ProtectedRoute: React.FC = () => {
  const { tokenUser, user, isLoading, isVisitor = true } = useAuth();
  const location = useLocation();

  if (isLoading) {
    return <div>Loading...</div>;
  }

  // Check if the user is authenticated
  if (!user || !tokenUser) {
    return <Navigate to="/login" />;
  }

  // If user is not a visitor and trying to access any page other than onboarding
  if (!isVisitor && location.pathname !== "/onboarding") {
    return <Navigate to="/onboarding" />;
  }

  return <Outlet />;
};

export default ProtectedRoute;
