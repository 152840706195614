import React, { useState, useRef, useEffect, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import styled from "styled-components";
import IconCircle from "../../src/assets/img/icons/LogoCircle.png";
import { Pen, BellPlus, FilePen } from "lucide-react";
import SearchBar from "../components/search/searchBar";
import PageContainer from "../components/containers/PageContainer";
import ModalInfo from "../components/nav/ModalInfo";
import useFeed from "../hooks/useFeed";
import Action from "../components/speech/Action";
import { TailSpin } from "react-loader-spinner";
import LogoBackground from "../assets/img/logos/logoBackground.svg";
import { Feed } from "../types/Feed";
import useCountSignals from "../hooks/useCountSignals";
import axios from "axios";

const HomeHero = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 25px;
  width: 100%;
  box-sizing: border-box;
`;

const TitleHeader = styled.h1`
  color: #fff;
  font-family: "Sharp Sans";
  font-size: 28px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  margin: 0;
  padding: 0;
`;

const BackgroundHeader = styled.img`
  width: 414px;
  box-sizing: border-box;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 10;
`;

const DescriptionHeader = styled.p`
  color: #fff;
  font-family: "Sharp Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
  padding: 0;
  span {
    color: #ff734f;
    font-weight: 700;
  }
`;

const Header = styled.div`
  display: flex;
  height: 135px;
  padding: 0px 40px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 5px;
  align-self: stretch;
  border-radius: 19px;
  background: #232129;
  width: calc(100% - 80px);
  box-sizing: border-box;
  width: 100%;
  position: relative;
`;

const SearchContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 700px;
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const FeedContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
  flex: 1 0 0;
  align-self: stretch;
  width: 100%;
  border-radius: 18px;
  border: 1px solid var(--Main-stroke-1, #ebebeb);
  box-sizing: border-box;
`;

const HeaderFeed = styled.div`
  display: flex;
  padding: 20px 30px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
  width: 100%;

  border-bottom: 1px solid var(--Main-stroke-1, #ebebeb);
  min-height: 50px;
  box-sizing: border-box;
`;

const FeedTitle = styled.h2`
  color: #3c3c3d;
  font-family: "Sharp Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.18px;
  margin: 0;
  padding: 0;
`;

const FeedDescription = styled.p`
  color: #6e6e6e;
  font-family: "Sharp Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  margin: 0;
  padding: 0;
`;

const FeedContent = styled.div`
  display: flex;
  padding: 0px 20px;
  padding-bottom: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
  align-self: stretch;
`;

const FeedList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;
  align-self: stretch;
`;

const Home = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  console.log("user :", user);
  const [showModal, setShowModal] = useState(false);
  const { data, loading, error, loadMore } = useFeed(user?.user_id);
  const feedRef = useRef<HTMLDivElement>(null);
  const {
    count,
    loading: countLoading,
    error: countError,
  } = useCountSignals(user?.user_id);

  // Uppercase to the first letter of the username
  const username =
    user?.username.charAt(0).toUpperCase() + user?.username.slice(1);

  console.log("data :", user);

  useEffect(() => {
    console.log("Loading more");
    const observer = new IntersectionObserver(
      (entries) => {
        const lastEntry = entries[0];
        if (lastEntry.isIntersecting && data?.hasMore && !loading) {
          loadMore();
        }
      },
      { threshold: 0.5 }
    );

    const currentFeedRef = feedRef.current;
    if (currentFeedRef) {
      observer.observe(currentFeedRef);
    }

    return () => {
      if (currentFeedRef) {
        observer.unobserve(currentFeedRef);
      }
    };
  }, [data?.hasMore, loading, loadMore]);

  const GetUrl = (data: Feed) => {
    if (data.type === "amendment") {
      return `file/${data.fileId}/amendts/${data.id}`;
    }
    if (data.type === "speech") {
      return `#`;
    }
    return `#`;
  };

  const handleCampaignClick = (e: React.MouseEvent) => {
    e.preventDefault();
    setShowModal(true);
  };
  return (
    <PageContainer>
      <HomeHero>
        <SearchContainer>
          <SearchBar />
        </SearchContainer>
        <Header>
          <TitleHeader>Bienvenue {username}</TitleHeader>
          <DescriptionHeader>
            Vous avez{" "}
            <span>
              {count?.count || 0} signa
              {count?.count === 0 || count?.count === 1 || count?.count === null
                ? "l"
                : "ux"}
            </span>{" "}
            configuré
            {count?.count === 0 || count?.count === 1 || count?.count === null
              ? ""
              : "s"}
            .
          </DescriptionHeader>
          <BackgroundHeader src={LogoBackground} />
        </Header>
        {showModal && (
          <ModalOverlay>
            <ModalInfo
              title="Fonctionnalité verrouillée"
              description="Ce contenu est bloqué dans la version de Parabole que vous utilisez. Contactez-nous pour plus d'informations."
              onClose={() => setShowModal(false)}
            />
          </ModalOverlay>
        )}
        <FeedContainer>
          <HeaderFeed>
            <FeedTitle>
              Les activités législatives qui vous concernent
            </FeedTitle>
            <FeedDescription>
              Retrouvez ici un fil d'actualité entièrement personnalisé basé sur
              les signaux que vous avez configurés.
            </FeedDescription>
          </HeaderFeed>
          <FeedContent>
            {error ? (
              <p>Error: {error}</p>
            ) : (
              <FeedList>
                {data?.feed.map(
                  (item, index) =>
                    item && (
                      <Action
                        key={index}
                        fullName={item.fullName || ""}
                        deputyId={item.deputyId || ""}
                        text={item.content || ""}
                        score={item.score || "low"}
                        url={GetUrl(item)}
                        signalName={item.signalName || ""}
                        type={item.type || ""}
                      />
                    )
                )}
                {/* If no data, show a message */}
                {data?.feed.length === 0 && count?.count === null && (
                  <FeedDescription
                    //Navigate to the signals page
                    onClick={() => navigate("/alerts")}
                    style={{
                      textAlign: "center",
                      width: "100%",
                      height: "100px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      cursor: "pointer",
                    }}
                  >
                    Commencez par configurer au moins un signal pour retrouver
                    des activités législatives qui vous concernent.
                  </FeedDescription>
                )}
                {data?.feed.length === 0 && count?.count != null && (
                  <FeedDescription
                    style={{
                      textAlign: "center",
                      width: "100%",
                      height: "100px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    Parabole n'a trouvé aucune activité vous concernant.
                  </FeedDescription>
                )}

                {loading && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <TailSpin
                      visible={true}
                      height="50"
                      width="50"
                      color="#FF734F"
                      radius="12.5"
                      ariaLabel="mutating-dots-loading"
                    />
                  </div>
                )}
                <div
                  ref={feedRef}
                  style={{ height: "20px", backgroundColor: "red" }}
                />
              </FeedList>
            )}
          </FeedContent>
        </FeedContainer>
      </HomeHero>
    </PageContainer>
  );
};

export default Home;
