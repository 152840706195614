import React from "react";
import styled from "styled-components";
import { MessageCircle } from "lucide-react";
import { FeedbackFish } from "@feedback-fish/react";

const FeedbackButtonContainer = styled.button`
  position: fixed;
  bottom: 40px;
  right: 20px;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: #ff734f;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease;
  z-index: 1000;

  &:hover {
    transform: scale(1.1);
  }
`;

const FeedbackButton = () => {
  return (
    <FeedbackFish projectId="6173146c58611a">
      <FeedbackButtonContainer>
        <MessageCircle size={24} color="white" />
      </FeedbackButtonContainer>
    </FeedbackFish>
  );
};

export default FeedbackButton;
