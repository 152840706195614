import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import Tooltip from "../cta/Tooltip";
// Define an interface for the props

interface Field {
  name: string;
  link: string;
  disabled?: boolean;
}

interface MenuFieldProps {
  iconSrc: string;
  text: string;
  link: string;
  hasText?: boolean;
  hasAmendments?: boolean;
  hasSpeeches?: boolean;
  fields?: Field[];
  hidden?: boolean;
}
const MenuFieldHero = styled.div<{ hidden?: boolean }>`
  display: flex;
  padding: 22px 192px;
  justify-content: center;
  align-items: center;
  gap: 50px;
  align-self: stretch;
  border-radius: 20px;
  border: 1px solid var(--Main-stroke-1, #ebebeb);
  background: #fcfcfc;
  display: ${({ hidden }) => (hidden ? "none" : "flex")};
`;

const Element = styled(Link)<{ disabled?: boolean; isActive?: boolean }>`
  color: ${({ disabled, isActive }) =>
    disabled ? "#CACACA" : isActive ? "#FF734F" : "#232129"};
  text-align: center;
  font-family: "Sharp Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-decoration: none;
  pointer-events: ${({ disabled }) => (disabled ? "none" : "auto")};
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
`;

const FileMenu: React.FC<MenuFieldProps> = ({
  text,
  iconSrc,
  link,
  hasText = true,
  hasAmendments = true,
  hasSpeeches = true,
  fields,
  hidden,
}) => {
  const [isActive, setIsActive] = useState(fields?.[0]?.name || "");
  return (
    <MenuFieldHero hidden={hidden}>
      {fields &&
        fields.map((field) => (
          <Element
            key={field.link}
            to={field.link}
            disabled={field.disabled}
            onClick={() => setIsActive(field.name)}
            isActive={isActive === field.name}
          >
            {field.name}
          </Element>
        ))}
    </MenuFieldHero>
  );
};

export default FileMenu;
