import { Loader } from "lucide-react";
import React from "react";
import { Oval } from "react-loader-spinner";
import styled, { css, keyframes } from "styled-components";

interface ButtonProps {
  typee?: string;
  type?: "submit" | "reset" | "button" | undefined;
  disabled?: boolean;
  children?: React.ReactNode;
  typeButton: "primary" | "secondary";
  stretch?: boolean;
  onClick?: () => void;
  isLoading?: boolean;
}

const getBorderColor = (typeButton: string) => {
  switch (typeButton) {
    case "primary":
      return "#FF734F";
    case "secondary":
      return "#fff";
  }
};

const getBorder = (typeButton: string) => {
  switch (typeButton) {
    case "primary":
      return "0px solid #fff";
    case "secondary":
      return "1px solid #FF734F";
  }
};

const getButtonColor = (typeButton: string) => {
  switch (typeButton) {
    case "primary":
      return "#FF734F";
    case "secondary":
      return "transparent";
  }
};

const getTextColor = (typeButton: string) => {
  switch (typeButton) {
    case "primary":
      return "#fff";
    case "secondary":
      return "#FF734F";
  }
};

const getButtonFontSize = (typeButton: string) => {
  switch (typeButton) {
    case "primary":
      return "14px";
    case "secondary":
      return "14px";
  }
};

const ButtonHero = styled.button<ButtonProps>`
  display: flex;
  cursor: pointer;
  padding: 12px 20px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 5px;
  border: ${(props) =>
    props.isLoading ? "1px solid #EBEBEB" : getBorder(props.typeButton)};
  background-color: ${(props) =>
    props.isLoading ? "#F8F8F8" : getButtonColor(props.typeButton)};
  color: ${(props) =>
    props.isLoading ? "#A1A1A1" : getTextColor(props.typeButton)};
  font-family: "Sharp Sans";
  font-size: ${(props) => getButtonFontSize(props.typeButton)};
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  align-self: ${(props) => (props.stretch ? "stretch" : "auto")};
`;
const Button: React.FC<ButtonProps> = ({
  type,
  disabled,
  children,
  typeButton,
  stretch,
  onClick,
  isLoading,
}) => {
  return (
    <ButtonHero
      typeButton={typeButton}
      type={type}
      disabled={disabled}
      stretch={stretch}
      onClick={onClick}
      isLoading={isLoading}
    >
      {isLoading ? (
        <Oval
          visible={true}
          height="16"
          width="16"
          color="#FF734F"
          ariaLabel="oval-loading"
          wrapperStyle={{}}
          wrapperClass=""
        />
      ) : (
        ""
      )}
      {children}
    </ButtonHero>
  );
};

export default Button;
