import React, { useState, useEffect, useCallback } from "react";
import Table from "../../components/table/Table";
import styled from "styled-components";
import Category from "../../components/text/Category";
import Alert from "../../components/alerts/Alert";
import Button from "../../components/cta/Button";
import { useNavigate } from "react-router-dom";
import useAlert from "../../hooks/useAlert";
import { Signal as SignalType } from "../../types/Signal"; // Assuming you have this type defined
import PageContainer from "../../components/containers/PageContainer";
import SearchBar from "../../components/search/searchBar";
import Loader from "../../components/interaction/Loader";

const TableContainer = styled.div`
  width: 100%;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
`;

const SearchContainer = styled.div`
  width: 600px;
`;

const Alerts: React.FC = () => {
  const navigate = useNavigate();
  const { getAlertList, loading, error } = useAlert();

  const [signals, setSignals] = useState<SignalType[]>([]);

  useEffect(() => {
    getAlertList().then((data) => {
      if (data) setSignals(data);
    });
  }, [getAlertList]);

  console.log("Current alerts state:", signals);

  const columns = ["Nom", "Statut", "Nouveaux"];

  const customRenderers = {
    Nom: (value: any, row: any) => {
      return row.name;
    },
    Statut: (value: any, row: any) => {
      const status = () => {
        if (row.status === true) {
          return <Category>Actif</Category>;
        } else if (row.status === false) {
          return <Category>Inactif</Category>;
        }
      };
      return status();
    },
    Nouveaux: (value: any, row: any) => {
      return <Alert number={row.newElements} />;
    },
  };

  const actionLink = () => {
    const signalId = signals.map((signal) => signal.signal_id);
    console.log("Alert ID:", signalId);
    return `/alerts/${signalId}/amendments`;
  };

  useEffect(() => {
    actionLink();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageContainer>
      <SearchContainer>
        <SearchBar />
      </SearchContainer>
      <ButtonContainer>
        <Button
          typeButton="secondary"
          onClick={() => {
            navigate("/alerts/config");
          }}
        >
          Configurer un signal
        </Button>
      </ButtonContainer>
      <TableContainer>
        {error ? (
          <p style={{ textAlign: "center", marginTop: "70px" }}>
            Il est impossible d'afficher les signaux pour le moment. Si je
            problème persiste, veuillez utiliser le chat.
          </p>
        ) : loading ? (
          <Loader />
        ) : (
          <Table
            columns={columns}
            data={signals}
            actionLink="signal_id"
            customRenderers={customRenderers}
            emptyDateMessage="Aucune alerte configurée pour le moment"
          />
        )}
      </TableContainer>
    </PageContainer>
  );
};

export default Alerts;
