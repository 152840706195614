import { useState, useEffect } from "react";
import { MAIN_URL } from "../config";
import axios from "axios";

interface Profil {
  deputyId: string;
  fullName: string;
  groupNameShort: string;
  firstName: string;
  lastName: string;
  age: number;
  circonscription: string;
  department: string;
  job: string;
  amendmentsCount: number;
  amendmentsAccepted: number;
}

interface UseProfilDataResponse {
  data: Profil | null;
  loading: boolean;
  error: string | null;
}

const useProfil = (deputyId?: string): UseProfilDataResponse => {
  const [data, setData] = useState<Profil | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      if (!deputyId) return;

      setLoading(true);
      setError(null);

      try {
        const response = await axios.get(`${MAIN_URL}people/profil`, {
          //const response = await axios.get(`http://localhost:1330/profil`, {
          params: {
            deputyId: deputyId,
          },
        });
        setData(response.data.data);
      } catch (err) {
        if (axios.isAxiosError(err) && err.response) {
          setError(`Error: ${err.response.statusText}`);
        } else if (err instanceof Error) {
          setError(err.message);
        } else {
          setError("An unknown error occurred");
        }
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [deputyId]);

  return { data, loading, error };
};

export default useProfil;
