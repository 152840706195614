import { useState, useCallback, useEffect, useMemo } from "react";
import { useOutletContext, useParams } from "react-router-dom";
import styled from "styled-components";
import AmdtsExplorer from "../../../components/pageSection/AmdtsExplorer";
interface OutletContext {
  deputyId: string;
  userId: string;
}

const AmendmentsHero = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  width: 100%;
  padding-bottom: 30px;
`;
/*
const Modules = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
`;*/

const Module = styled.div<{ flexGrow?: number }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: ${({ flexGrow }) => flexGrow || 1} 0 0;
`;

const Filters = styled.div`
  border-radius: 20px;
  border: 1px solid var(--Main-stroke-1, #ebebeb);
`;

const Header = styled.div`
  display: flex;
  height: 64px;
  padding: 0px 28px;
  align-items: center;
  align-self: stretch;
  border-radius: 20px 20px 0px 0px;
  border-bottom: 1px solid var(--Main-stroke-1, #ebebeb);
  background: #fcfcfc;
`;

const Title = styled.span`
  display: flex;
  align-items: center;
  gap: 12px;
  flex: 1 0 0;
  color: #3c3c3d;
  font-family: "Sharp Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
`;

const Params = styled.div`
  display: flex;
  padding: 0px 28px;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  align-self: stretch;
`;

const FilterContainer = styled.div`
  display: flex;
  padding: 10px 0px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;
  border-bottom: 1px solid var(--Main-stroke-1, #ebebeb);
`;

const Choice = styled.div`
  display: flex;
  padding: 20px 28px;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
  border-radius: 0px 0px 20px 20px;
  border: 1px solid var(--Main-stroke-1, #ebebeb);
`;

const ChoiceText = styled.button`
  color: #828283;
  font-family: "Sharp Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 12px; /* 100% */
  border: none;
  background: none;
  cursor: pointer;
`;

const AmendtsProfil = () => {
  const { deputyId, userId } = useOutletContext<OutletContext>();
  return (
    <AmendmentsHero>
      <AmdtsExplorer deputyId={deputyId} userId={userId} />
    </AmendmentsHero>
  );
};

export default AmendtsProfil;
