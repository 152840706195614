import Category from "../text/Category";
import styled from "styled-components";

interface ScoreProps {
  children: string;
}

const GetScoreText = (score?: string) => {
  if (score === "medium") {
    return "Moyen";
  } else if (score === "high") {
    return "Haut";
  } else {
    return "Faible";
  }
};

const GetScoreBackgroundColor = (score?: string) => {
  if (score === "medium") {
    return "#FDF4E4";
  } else if (score === "high") {
    return "#FFF2F2";
  } else {
    return "#eeeeee";
  }
};

const GetScoreTextColor = (score?: string) => {
  if (score === "medium") {
    return "#B9B07B";
  } else if (score === "high") {
    return "#B97B7B";
  } else {
    return "#6e6e6e";
  }
};

const ScoreContainer = styled.span`
  display: flex;
  padding: 0px 12px;
  align-items: flex-start;
  gap: 10px;
  border-radius: 18px;
  background: #fff2f2;
  color: #b97b7b;
  font-family: "Sharp Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 183.333% */
`;

const Score: React.FC<ScoreProps> = ({ children }) => (
  <ScoreContainer
    style={{
      backgroundColor: GetScoreBackgroundColor(children),
      color: GetScoreTextColor(children),
    }}
  >
    {GetScoreText(children)}
  </ScoreContainer>
);

export default Score;
