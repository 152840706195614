import { useState, useEffect } from "react";
import { MAIN_URL } from "../config";
import axios from "axios";

interface Paragraph {
  text: string;
  number: number;
  wordsSaved: string[];
  paragraphId: string;
  table?: string;
  text_align?: string;
}

interface Chapter {
  name: string;
  number: number;
  Article: Article[];
  title: string;
}

interface Article {
  article_id: string;
  number: number;
  Paragraph: Paragraph[];
  title: string;
  order: number;
  statement?: string;
  score?: string;
  signal_name?: string;
}

interface TextSection {
  title: string;
  number: number;
  Chapter: Chapter[];
  sub_title: string;
}

interface TextSubType {
  name: string;
  TextSection: TextSection[];
}

interface TextType {
  name: string;
  TextSubType: TextSubType[];
}

interface LawText {
  TextSection?: TextSection[];
  TextType?: {
    name: string;
    TextSubType: {
      name: string;
      TextSection: TextSection[];
    }[];
  }[];
  text_id: string;
  html: string | null;
}

interface UseLawTextResponse {
  data: LawText | null;
  loading: boolean;
  error: string | null;
}

const decodeHtml = (html: string): string => {
  const txt = document.createElement("textarea");
  txt.innerHTML = html;
  return txt.value;
};

const useText = (
  uid: string,
  rank: number,
  title: string,
  subtitle: string,
  stageFileId: string,
  user_id: number,
  fileTitle: string
): UseLawTextResponse => {
  const [data, setData] = useState<LawText | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);
      try {
        console.log("Fetching with params:", {
          uid,
          title: subtitle,
          subtitle: title,
          stageFileId,
          user_id,
        });

        const response = await axios.get(`${MAIN_URL}textprovider/lawText`, {
          //const response = await axios.get(`http://localhost:1080/lawText`, {
          params: {
            uid: uid,
            user_id: user_id,
            stageFileId: stageFileId,
            name: fileTitle,
          },
        });

        if (response.data.html) {
          response.data.html = decodeHtml(response.data.html);
        } else {
          console.log("No HTML in response data");
        }

        setData(response.data);
      } catch (err) {
        if (axios.isAxiosError(err) && err.response) {
          setError(`Error: ${err.response.statusText}`);
          console.error("API Error:", err.response);
        } else if (err instanceof Error) {
          setError(err.message);
          console.error("General Error:", err);
        } else {
          setError("An unknown error occurred");
          console.error("Unknown Error:", err);
        }
      } finally {
        setLoading(false);
      }
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uid, rank, title, subtitle]);
  return { data, loading, error };
};

export default useText;
