import React from "react";
import styled from "styled-components";

interface InteractionProps {
  children: React.ReactNode;
  disabled?: boolean;
}

const Interactionhero = styled.div<{ disabled?: boolean }>`
  display: flex;
  padding: 1px 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  border: 1px solid ${(props) => (props.disabled ? "#828283" : "#ff734f")};
  background: ${(props) =>
    props.disabled ? "#FAFAFA" : "var(--Second-color, #fff7f2)"};
  cursor: pointer;
`;

const Text = styled.div<{ disabled?: boolean }>`
  color: ${(props) =>
    props.disabled ? "#828283" : "var(--Main-color, #ff734f)"};
  font-family: "Sharp Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
`;

const Interaction: React.FC<InteractionProps> = ({ children, disabled }) => {
  return (
    <Interactionhero disabled={disabled}>
      <Text disabled={disabled}>{children}</Text>
    </Interactionhero>
  );
};

export default Interaction;
