import { useState, useEffect, useCallback } from "react";
import { MAIN_URL } from "../config";
import axios from "axios";
import { FeedResponse } from "../types/Feed";
const useFeed = (userId?: number) => {
  const [data, setData] = useState<FeedResponse | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [page, setPage] = useState(1);

  // useCallback is used to memoize the fetchFeed function
  const fetchFeed = useCallback(
    async (pageNum: number) => {
      try {
        const response = await axios.get(
          `${MAIN_URL}/textprovider/feed`,
          //`http://localhost:1080/feed`,
          {
            params: {
              userId: userId,
              page: pageNum,
            },
          }
        );
        return {
          feed: response.data.feed,
          hasMore: pageNum < response.data.total_pages,
          total_pages: response.data.total_pages,
        };
      } catch (err) {
        throw err;
      }
    },
    [userId]
  );

  const loadMore = async () => {
    if (!loading && data?.hasMore) {
      setLoading(true);
      try {
        const newData = await fetchFeed(page + 1);
        setData((prev) => ({
          feed: [...(prev?.feed || []), ...newData.feed],
          hasMore: newData.hasMore,
          total_pages: newData.total_pages,
        }));
        setPage((prev) => prev + 1);
      } catch (err) {
        setError(err instanceof Error ? err.message : "An error occurred");
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    const initialFetch = async () => {
      setLoading(true);
      try {
        const initialData = await fetchFeed(1);
        setData(initialData);
      } catch (err) {
        setError(err instanceof Error ? err.message : "An error occurred");
      } finally {
        setLoading(false);
      }
    };

    if (userId) {
      initialFetch();
    }
  }, [userId, fetchFeed]);

  return { data, loading, error, loadMore };
};

export default useFeed;
