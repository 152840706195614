import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import searchIcon from "../../../assets/img/icons/search.png";
import Collapser from "../../../components/legal/Collasper";
import useText from "../../../hooks/useText";
import { useOutletContext } from "react-router-dom";
import NoText from "../../../components/text/NoText";
import useAuth from "../../../hooks/useAuth";
import Loader from "../../../components/interaction/Loader";
import Button from "../../../components/cta/Button";
import useAnalysis, { AnalysisStatus } from "../../../hooks/useAnalysis";

interface OutletContext {
  rank: number;
  title: string;
  subtitle: string;
  textId: string;
  stageFileId: string;
  fileId: string;
  fileTitle: string;
}

interface ChapterProps {
  number: number;
}
const HtmlContainer = styled.div`
  iframe {
    width: 100%;
    border: none;
    height: auto;
    min-height: 500px;
    overflow: hidden;
    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
`;

const Modules = styled.div`
  display: flex;
  width: 100%;
  height: 1100px;
  align-items: flex-start;
  gap: 20px;
  align-self: stretch;
`;

const Module = styled.div`
  display: flex;
  padding: 0px 28px 26px 28px;
  flex-direction: column;
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;
  border-radius: 18px;
  border: 1px solid var(--Main-stroke-1, #ebebeb);
  background: #fff;
  overflow-y: scroll;
  width: 100%;
`;

const TopText = styled.div<{ display: string }>`
  display: ${(props) => props.display};
  flex-direction: row;
  align-items: flex-end;
  align-self: stretch;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 100;
  background-color: #ffffff;
  padding: 10px 0px;
  border-bottom: 1px solid #ebebeb;
  margin-bottom: 20px;
`;

const Search = styled.button`
  display: flex;
  width: 34px;
  height: 34px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 20px;
  background: #fff;
  border: 1px solid var(--Main-stroke-1, #ebebeb);

  img {
    width: 186x;
    height: 16px;
  }
`;

const TextTitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 13px;
  align-self: stretch;
  padding-bottom: 25px;
`;

const TitleNumber = styled.span`
  color: #232129;

  text-align: center;
  font-family: "Sharp Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

const TitleString = styled.span`
  max-width: 663.896px;
  color: #232129;
  text-align: center;
  font-family: "Sharp Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

const ChapterTitle = styled.div<ChapterProps>`
  display: ${(props) => (props.number > 0 ? "flex" : "none")};
  flex-direction: column;
  align-items: center;
  gap: 13px;
  align-self: stretch;
  padding-bottom: 10px;
`;

const ChapterNumber = styled.span`
  color: #232129;
  text-align: center;
  font-family: "Sharp Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

const TypeTitle = styled.h1`
  color: #232129;
  text-align: center;
  font-family: "Sharp Sans";
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

const SubTypeTitle = styled.h2`
  color: #232129;
  text-align: center;
  font-family: "Sharp Sans";
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

const ChapterName = styled.span`
  width: 663.896px;
  height: 50.581px;
  color: #232129;
  text-align: center;
  font-family: "Sharp Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 300px;
  font-family: "Sharp Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  color: #232129;
`;

const TextHandler = () => {
  const { fileId } = useParams<{ fileId: string }>();
  const { user } = useAuth();
  const [isOpen, setIsOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const { rank, title, subtitle, textId, stageFileId, fileTitle } =
    useOutletContext<OutletContext>();
  console.log("textId", textId);
  console.log("stageFileId", stageFileId);
  console.log("fileId", fileId);
  console.log("rank", rank);
  console.log("title", title);
  console.log("subtitle", subtitle);
  console.log("fileTitle", fileTitle);
  const { data, loading, error } = useText(
    fileId || "",
    rank,
    title,
    subtitle,
    stageFileId,
    user.user_id,
    fileTitle
  );
  const {
    startAnalysis,
    analysisStatus,
    error: analysisError,
  } = useAnalysis(textId, user.user_id);

  const handleAnalysis = async () => {
    if (textId && user.user_id && analysisStatus !== AnalysisStatus.ENDED) {
      await startAnalysis(textId, user.user_id);
    }
  };

  useEffect(() => {
    if (analysisStatus === AnalysisStatus.STARTED) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
    console.log("analysisStatus:", analysisStatus);
  }, [analysisStatus]);

  console.log(analysisStatus);

  const getButtonText = (status: AnalysisStatus) => {
    switch (status) {
      case AnalysisStatus.STARTED:
        return "Analyse en cours...";
      case AnalysisStatus.ERROR:
        return "Erreur d'analyse";
      case AnalysisStatus.ENDED:
        return "Analyse terminée";
      default:
        return "Analyser ce texte";
    }
  };

  const getButtonDisabled = (status: AnalysisStatus) => {
    return status === AnalysisStatus.STARTED;
  };

  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  if (!textId) {
    return <NoText name="file-text" />;
  }

  console.log(data?.TextType);
  return (
    <Modules>
      <Module>
        <TopText display={AnalysisStatus.ERROR ? "none" : "flex"}>
          <Button
            typeButton="primary"
            onClick={handleAnalysis}
            disabled={getButtonDisabled(analysisStatus)}
            isLoading={isLoading}
          >
            {getButtonText(analysisStatus)}
          </Button>
          <Search>
            <img src={searchIcon} alt="Search" />
          </Search>
        </TopText>
        {data ? (
          data.TextSection ? (
            data.TextSection.map((section) => (
              <div key={section.number}>
                <TextTitle>
                  <TitleNumber>
                    {section.title !== "Default Section" ? section.title : ""}
                  </TitleNumber>
                  <TitleString>{section.sub_title}</TitleString>
                </TextTitle>
                {section.Chapter.map((Chapter) => (
                  <div key={Chapter.number}>
                    <ChapterTitle number={Chapter.number}>
                      <ChapterNumber> {Chapter.name}</ChapterNumber>
                      <ChapterName>{Chapter.title}</ChapterName>
                    </ChapterTitle>
                    {Chapter.Article.map((article) => (
                      <Collapser
                        key={article.number}
                        title={article.title}
                        paragraph={article.Paragraph}
                        score={article.score}
                        signal_name={article.signal_name}
                      />
                    ))}
                  </div>
                ))}
              </div>
            ))
          ) : data.TextType ? (
            data.TextType.map((type) => (
              <div key={type.name}>
                <TypeTitle>{type.name}</TypeTitle>
                {type.TextSubType.map((subType) => (
                  <div key={subType.name}>
                    <SubTypeTitle>{subType.name}</SubTypeTitle>
                    {subType.TextSection.map((section) => (
                      <div key={section.number}>
                        <TextTitle>{section.title}</TextTitle>
                        {section.Chapter.map((chapter) => (
                          <div key={chapter.number}>
                            <ChapterTitle number={chapter.number}>
                              <ChapterNumber>{chapter.title}</ChapterNumber>
                            </ChapterTitle>
                            {chapter.Article.map((article) => (
                              <div key={article.number}>
                                <Collapser
                                  title={`ARTICLE ${article.number}`}
                                  subTitle={article.title}
                                  paragraph={article.Paragraph}
                                  statement={article.statement}
                                  score={article.score}
                                  signal_name={article.signal_name}
                                />
                              </div>
                            ))}
                          </div>
                        ))}
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            ))
          ) : (
            <ErrorContainer>
              <p>
                La version actuelle de Parabole ne permet pas de visualiser ce
                texte grâce au Smart Reader.
              </p>
            </ErrorContainer>
          )
        ) : (
          <ErrorContainer>
            <p>
              La version actuelle de Parabole ne permet pas de visualiser ce
              texte grâce au Smart Reader.
            </p>
          </ErrorContainer>
        )}
      </Module>
    </Modules>
  );
};

export default TextHandler;
