import { useState, useEffect, useMemo } from "react";
import { MAIN_URL } from "../config";
import axios from "axios";

interface Speeches {
  speechId: string;
  text: string;
  deputyId: string;
  fullName: string;
  date: string;
  typeMeeting: string;
  score: string;
  fileName: string;
}

interface SpeechesData {
  Speeches: Speeches[];
  total_pages: number;
}

interface UseSpeechDataResponse {
  data: SpeechesData | null;
  loading: boolean;
  error: string | null;
}

const useSpeech = (
  fileId?: string,
  deputyId?: string,
  page?: number,
  userId?: string,
  filters?: {
    id: number;
    metric: {
      label: string;
      value: string;
    }[];
    symbol: {
      label: string;
      value: string;
    }[];
    value: {
      label: string;
      value: string;
    }[];
  }[],
  signalId?: string,
  speechId?: string
): UseSpeechDataResponse => {
  const [data, setData] = useState<SpeechesData | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const memoizedFilters = useMemo(() => JSON.stringify(filters), [filters]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);

      try {
        const params: any = {
          page: page,
        };
        if (signalId) {
          console.log("Signal hook:", signalId);
          params.signalId = signalId;
          params.userId = userId;
        }
        if (deputyId) {
          console.log("Deputy hook:", deputyId);
          params.deputyId = deputyId;
          params.userId = userId;
        }
        if (filters && filters.some((filter) => filter || filter || filter)) {
          params.filters = JSON.stringify(filters);
        }
        if (speechId) {
          params.speechId = speechId;
        }
        const response = await axios.get(`${MAIN_URL}/textprovider/speeches`, {
          //const response = await axios.get(`http://localhost:1080/speeches`, {
          params,
        });
        setData(response.data);
      } catch (err) {
        if (axios.isAxiosError(err) && err.response) {
          setError(`Error: ${err.response.statusText}`);
        } else if (err instanceof Error) {
          setError(err.message);
        } else {
          setError("An unknown error occurred");
        }
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [page, deputyId, filters, signalId, userId, speechId]);
  console.log("Speech", data);

  return { data, loading, error };
};

export default useSpeech;
