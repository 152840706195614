import { useState, useEffect, useMemo } from "react";
import axios from "axios";

const useInteraction = (interactionId?: string): any => {
  const [data, setData] = useState<any | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);

      try {
        const params: any = {
          interactionId: interactionId,
        };

        const response = await axios.get(
          `https://api.oparl-app.com/textprovider/interactiondetails`,
          //`http://localhost:1080/interactiondetails`,
          {
            params,
          }
        );
        setData(response.data);
        console.log("response.data", response.request);
      } catch (err) {
        if (axios.isAxiosError(err) && err.response) {
          setError(`Error: ${err.response.statusText}`);
        } else if (err instanceof Error) {
          setError(err.message);
        } else {
          setError("An unknown error occurred");
        }
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [interactionId]);

  return { data, loading, error };
};

export default useInteraction;
