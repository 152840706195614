import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { X } from "lucide-react";

const BannerContainer = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #ff734f;
  color: white;
  padding: 8px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5000;
  font-family: "Sharp Sans";
  box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.1);
  height: 150px;
`;

const BannerContent = styled.div`
  text-align: center;
  font-size: 14px;
  max-width: 800px;
  margin: 0 auto;
  line-height: 1.5;
  font-family: "Sharp Sans";
`;

const CloseButton = styled.button`
  position: absolute;
  right: 16px;
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 4px;

  &:hover {
    opacity: 0.8;
  }
`;

const BetaBanner = () => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const closedTimestamp = localStorage.getItem("betaBannerClosedAt");

    if (closedTimestamp) {
      const closedDate = new Date(parseInt(closedTimestamp));
      const now = new Date();

      // Check if the banner was closed on a different day
      const isSameDay =
        closedDate.getDate() === now.getDate() &&
        closedDate.getMonth() === now.getMonth() &&
        closedDate.getFullYear() === now.getFullYear();

      setIsVisible(!isSameDay);
    }
  }, []);

  const handleClose = () => {
    const now = new Date().getTime();
    localStorage.setItem("betaBannerClosedAt", now.toString());
    setIsVisible(false);
  };

  if (!isVisible) return null;

  return (
    <BannerContainer>
      <BannerContent>
        🚧 Version bêta : Cette application est en cours de développement.
        Certaines fonctionnalités peuvent être instables ou indisponibles. Merci
        de votre compréhension.
      </BannerContent>
      <CloseButton onClick={handleClose}>
        <X size={20} />
      </CloseButton>
    </BannerContainer>
  );
};

export default BetaBanner;
