import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { logEvent } from "./amplitude";

const PageTracker: React.FC = () => {
  const location = useLocation();

  useEffect(() => {
    logEvent("Page Viewed", { page: location.pathname });
  }, [location]);

  return null;
};

export default PageTracker;
