import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import detailsIcon from "../../assets/img/icons/detailsIcon.png";
import DropDown from "../nav/DropDown";
import useTextSaved from "../../hooks/useTextSaved";
import useAuth from "../../hooks/useAuth";
import HighlightedSpan from "../text/HighlightedSpan";

interface ArticleProps {
  children: React.ReactNode;
  rank?: number;
  articleName?: string;
  title?: string;
  wordsSaved?: string[];
  paragraphId: string;
  subTitle?: string;
  textAlign?: string;
  table?: string;
}

const ArticleHero = styled.div`
  display: flex;
  width: 100%;
  height: auto;
  padding: 5px;
  align-items: flex-start;
  gap: 16px;
  text-align: left;
  border-radius: 10px;
  position: relative; /* Add position relative for absolute positioning */
  &:hover {
    background: #fff7f2;
  }
  overflow: visible;
`;

const DetailsHero = styled.div`
  width: 19px;
  flex-direction: column;
  align-items: center;
  gap: 2px;
  flex-shrink: 0;
  align-self: stretch;
  height: 100%;
  margin: 0;
  display: none; /* Default to hidden */
  ${ArticleHero}:hover & {
    display: flex;
  }
`;

const Number = styled.div`
  display: flex;
  width: 20px;
  height: 20px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 100px;
  border: 2px solid #6e6e6e;
  color: #6e6e6e;
  text-align: center;
  font-family: "Sharp Sans";
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
`;

const Content = styled.p<{ textAlign?: string }>`
  flex: 1 0 0;
  color: #6e6e6e;
  font-family: "Sharp Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px; /* 157.143% */
  margin: 0;
  text-indent: 25px;
  text-align: ${(props) => (props.textAlign ? "center" : "left")};
`;

const TableWrapper = styled.table`
  flex: 1 0 0;
  color: #6e6e6e;
  font-family: "Sharp Sans";
  font-size: 14px;
  font-weight: 500;
  td {
    border-color: #6e6e6e;
  }
`;

const Table = styled.div`
  width: 100%;
  height: 100%;
  border-color: #ebebeb;
`;

const Article: React.FC<ArticleProps> = ({
  children,
  rank,
  wordsSaved,
  paragraphId,
  subTitle,
  textAlign,
  table,
}) => {
  const [content, setContent] = useState<React.ReactNode>(children);
  const [highlightedTexts, setHighlightedTexts] = useState<string[]>([]);
  const [dropdownIsOpen, setDropdownIsOpen] = useState(false);
  const { user } = useAuth();
  const { savedTexts, saveText, error } = useTextSaved(
    paragraphId,
    user.user_id
  ); // Replace 1 with the actual user ID

  useEffect(() => {
    if (wordsSaved) {
      setHighlightedTexts(wordsSaved);
    }
  }, [wordsSaved]);

  useEffect(() => {
    updateHighlightedContent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [highlightedTexts, children]);

  useEffect(() => {
    const saveNewHighlight = async () => {
      if (highlightedTexts.length > 0) {
        const lastHighlight = highlightedTexts[highlightedTexts.length - 1];
        console.log("lastHighlight:", lastHighlight);
        if (!savedTexts.includes(lastHighlight)) {
          await saveText(lastHighlight);
        }
      }
    };

    saveNewHighlight();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [highlightedTexts]);

  const updateHighlightedContent = () => {
    if (typeof children === "string") {
      let parts: (string | React.ReactNode)[] = [children];
      highlightedTexts.forEach((text) => {
        // Escape special regex characters in the text
        const escapedText = text.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
        parts = parts.flatMap((part) =>
          typeof part === "string"
            ? part
                .split(new RegExp(`(${escapedText})`, "gi"))
                .map((chunk, i) =>
                  chunk.toLowerCase() === text.toLowerCase() ? (
                    <HighlightedSpan
                      key={`${text}-${i}`}
                      text={chunk}
                      paragraphId={text}
                      userId={user.user_id}
                      onCopy={() => handleCopy(chunk)}
                      onDelete={handleDelete}
                    />
                  ) : (
                    chunk
                  )
                )
            : part
        );
      });
      setContent(parts);
    } else {
      setContent(children);
    }
  };

  const handleCopy = (text: string) => {
    navigator.clipboard.writeText(text);
    // You can add a notification here if you want to inform the user that the text was copied
  };

  const handleDelete = (text: string) => {
    setHighlightedTexts((prevTexts) => prevTexts.filter((t) => t !== text));
  };

  const handleMouseUp = () => {
    const selection = window.getSelection();
    const selectedText = selection?.toString().trim();
    if (selectedText) {
      setHighlightedTexts((prevTexts) => [...prevTexts, selectedText]);
    }
  };

  const items = [
    {
      label: "Voir les amendements proposés",
      value: "amendments",
      available: false,
    },
    { label: "Comparer", value: "compare", available: false },
    { label: "Créer une alerte", value: "alert", available: false },
    { label: "Ajouter à une liste", value: "list", available: false },
  ];

  const handleSelect = (value: string) => {
    setHighlightedTexts((prevTexts) => [...prevTexts, value]);
  };

  // Changet dropdownIsOpen to false when the mouse leaves ArticleHero
  const handleMouseLeave = () => {
    setDropdownIsOpen(false);
  };

  return (
    <ArticleHero onMouseLeave={handleMouseLeave}>
      <Number>
        <span>{rank}</span>
      </Number>
      {typeof children === "string" && isHTML(children) ? (
        <TableWrapper dangerouslySetInnerHTML={{ __html: children }} />
      ) : (
        <Content onMouseUp={handleMouseUp} textAlign={textAlign}>
          {content}
          {table && <Table dangerouslySetInnerHTML={{ __html: table }} />}
        </Content>
      )}
      <DetailsHero>
        <DropDown
          src={detailsIcon}
          items={items}
          dropdownIsOpen={dropdownIsOpen}
          setDropdownIsOpen={setDropdownIsOpen}
        />
      </DetailsHero>
    </ArticleHero>
  );
};

const isHTML = (str: string): boolean => {
  const doc = new DOMParser().parseFromString(str, "text/html");
  return Array.from(doc.body.childNodes).some((node) => node.nodeType === 1);
};

export default Article;
